var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Login4Digit"},[(_vm.commandRunning)?_c('div',{staticClass:"CommandRunning"},[_c('div',{staticClass:"Upper"},[(
          !_vm.dayClosed &&
          !_vm.employeeNotFoundWithCode &&
          !_vm.employeeNotAllowedToLoginWith4Digit &&
          !_vm.supervisorNotFoundWithCode &&
          !_vm.supervisorNotAllowedToLoginWith4Digit &&
          !_vm.unknownError
        )?_c('div',[_c('i',{staticClass:"fas fa-user-lock fa-3x"})]):_vm._e(),(_vm.employeeNotFoundWithCode || _vm.supervisorNotFoundWithCode || _vm.unknownError)?_c('div',[_c('i',{staticClass:"fas fa-exclamation-triangle fa-3x"})]):_vm._e(),(
          _vm.employeeNotAllowedToLoginWith4Digit || _vm.supervisorNotAllowedToLoginWith4Digit
        )?_c('div',[_c('i',{staticClass:"fas fa-user-lock fa-3x"})]):_vm._e(),(_vm.dayClosed)?_c('div',[_c('i',{staticClass:"fas fa-user-lock fa-3x"})]):_vm._e()]),_c('div',{staticClass:"Middle"},[(
          !_vm.dayClosed &&
          !_vm.employeeNotFoundWithCode &&
          !_vm.employeeNotAllowedToLoginWith4Digit &&
          !_vm.supervisorNotFoundWithCode &&
          !_vm.supervisorNotAllowedToLoginWith4Digit &&
          !_vm.unknownError
        )?_c('div',[_vm._v(" Der eingegebene Code wird überprüft. ")]):_vm._e(),(_vm.employeeNotFoundWithCode || _vm.supervisorNotFoundWithCode)?_c('div',[_vm._v(" Der eingegebene Code ist falsch. ")]):_vm._e(),(
          _vm.employeeNotAllowedToLoginWith4Digit || _vm.supervisorNotAllowedToLoginWith4Digit
        )?_c('div',[_vm._v(" Die Anmeldung mit dem 4-stelligen Code wurde für Sie deaktiviert. ")]):_vm._e(),(_vm.dayClosed)?_c('div',[_vm._v(" Der aktuelle Tag wurde von einem Vorgesetzten vorzeitig gesperrt. ")]):_vm._e(),(_vm.unknownError)?_c('div',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.unknownErrorText)+" ")]):_vm._e()]),_c('div',{staticClass:"Lower"},[(
          !_vm.dayClosed &&
          !_vm.employeeNotFoundWithCode &&
          !_vm.employeeNotAllowedToLoginWith4Digit &&
          !_vm.supervisorNotFoundWithCode &&
          !_vm.supervisorNotAllowedToLoginWith4Digit
        )?_c('div',[_c('img',{attrs:{"src":"loading-balls-line.gif"}})]):_vm._e(),(_vm.employeeNotFoundWithCode || _vm.supervisorNotFoundWithCode)?_c('div',[_vm._v(" Es konnte kein Mitarbeiter mit diesem Code gefunden werden. ")]):_vm._e(),(
          _vm.employeeNotAllowedToLoginWith4Digit || _vm.supervisorNotAllowedToLoginWith4Digit
        )?_c('div',[_vm._v(" Ihr Vorgesetzter kann Ihnen diese Funktion freischalten. ")]):_vm._e(),(_vm.dayClosed)?_c('div',[_vm._v(" Es kann heute keine Aktion mehr am Stempel-Terminal durchgeführt werden. ")]):_vm._e()])]):_vm._e(),(!_vm.commandRunning)?_c('div',{staticClass:"LoginLeft"},[_c('div',{staticClass:"Upper"},[(_vm.$parent.employeeLogin)?_c('div',{staticClass:"Employee"},[_vm._v("Mitarbeiter - Login")]):_vm._e(),(!_vm.$parent.employeeLogin)?_c('div',{staticClass:"Admin"},[_vm._v("Administrator - Login")]):_vm._e()]),_c('div',{staticClass:"Lower"},[_c('span',{style:({
          'border-bottom':
            _vm.arrIndex <= 0
              ? _vm.arrIndex == 0
                ? '2px solid #7f7f7f'
                : '1px solid #c5c5c5'
              : '1px solid #fff',
        })},[_vm._v(_vm._s(_vm.visibleCode[0]))]),_c('span',{style:({
          'border-bottom':
            _vm.arrIndex <= 1
              ? _vm.arrIndex == 1
                ? '2px solid #7f7f7f'
                : '1px solid #c5c5c5'
              : '1px solid #fff',
        })},[_vm._v(_vm._s(_vm.visibleCode[1]))]),_c('span',{style:({
          'border-bottom':
            _vm.arrIndex <= 2
              ? _vm.arrIndex == 2
                ? '2px solid #7f7f7f'
                : '1px solid #c5c5c5'
              : '1px solid #fff',
        })},[_vm._v(_vm._s(_vm.visibleCode[2]))]),_c('span',{style:({
          'border-bottom':
            _vm.arrIndex <= 3
              ? _vm.arrIndex == 3
                ? '2px solid #7f7f7f'
                : '1px solid #c5c5c5'
              : '1px solid #fff',
        })},[_vm._v(_vm._s(_vm.visibleCode[3]))])])]):_vm._e(),(!_vm.commandRunning)?_c('div',{staticClass:"LoginMiddle"},[_c('div',{staticClass:"Background"},[_c('div',{staticClass:"Button",style:({
          color: 'rgb(' + _vm.color_r[1] + ',' + _vm.color_g[1] + ',' + _vm.color_b[1] + ')',
          'background-color':
            'rgb(' +
            _vm.background_color_r[1] +
            ',' +
            _vm.background_color_g[1] +
            ',' +
            _vm.background_color_b[1] +
            ')',
        }),on:{"click":function($event){return _vm.TapButton(1)}}},[_vm._v(" 1 ")]),_c('div',{staticClass:"Button",style:({
          color: 'rgb(' + _vm.color_r[2] + ',' + _vm.color_g[2] + ',' + _vm.color_b[2] + ')',
          'background-color':
            'rgb(' +
            _vm.background_color_r[2] +
            ',' +
            _vm.background_color_g[2] +
            ',' +
            _vm.background_color_b[2] +
            ')',
        }),on:{"click":function($event){return _vm.TapButton(2)}}},[_vm._v(" 2 ")]),_c('div',{staticClass:"Button",style:({
          color: 'rgb(' + _vm.color_r[3] + ',' + _vm.color_g[3] + ',' + _vm.color_b[3] + ')',
          'background-color':
            'rgb(' +
            _vm.background_color_r[3] +
            ',' +
            _vm.background_color_g[3] +
            ',' +
            _vm.background_color_b[3] +
            ')',
        }),on:{"click":function($event){return _vm.TapButton(3)}}},[_vm._v(" 3 ")]),_c('div',{staticClass:"Clear"}),_c('div',{staticClass:"Button",style:({
          color: 'rgb(' + _vm.color_r[4] + ',' + _vm.color_g[4] + ',' + _vm.color_b[4] + ')',
          'background-color':
            'rgb(' +
            _vm.background_color_r[4] +
            ',' +
            _vm.background_color_g[4] +
            ',' +
            _vm.background_color_b[4] +
            ')',
        }),on:{"click":function($event){return _vm.TapButton(4)}}},[_vm._v(" 4 ")]),_c('div',{staticClass:"Button",style:({
          color: 'rgb(' + _vm.color_r[5] + ',' + _vm.color_g[5] + ',' + _vm.color_b[5] + ')',
          'background-color':
            'rgb(' +
            _vm.background_color_r[5] +
            ',' +
            _vm.background_color_g[5] +
            ',' +
            _vm.background_color_b[5] +
            ')',
        }),on:{"click":function($event){return _vm.TapButton(5)}}},[_vm._v(" 5 ")]),_c('div',{staticClass:"Button",style:({
          color: 'rgb(' + _vm.color_r[6] + ',' + _vm.color_g[6] + ',' + _vm.color_b[6] + ')',
          'background-color':
            'rgb(' +
            _vm.background_color_r[6] +
            ',' +
            _vm.background_color_g[6] +
            ',' +
            _vm.background_color_b[6] +
            ')',
        }),on:{"click":function($event){return _vm.TapButton(6)}}},[_vm._v(" 6 ")]),_c('div',{staticClass:"Clear"}),_c('div',{staticClass:"Button",style:({
          color: 'rgb(' + _vm.color_r[7] + ',' + _vm.color_g[7] + ',' + _vm.color_b[7] + ')',
          'background-color':
            'rgb(' +
            _vm.background_color_r[7] +
            ',' +
            _vm.background_color_g[7] +
            ',' +
            _vm.background_color_b[7] +
            ')',
        }),on:{"click":function($event){return _vm.TapButton(7)}}},[_vm._v(" 7 ")]),_c('div',{staticClass:"Button",style:({
          color: 'rgb(' + _vm.color_r[8] + ',' + _vm.color_g[8] + ',' + _vm.color_b[8] + ')',
          'background-color':
            'rgb(' +
            _vm.background_color_r[8] +
            ',' +
            _vm.background_color_g[8] +
            ',' +
            _vm.background_color_b[8] +
            ')',
        }),on:{"click":function($event){return _vm.TapButton(8)}}},[_vm._v(" 8 ")]),_c('div',{staticClass:"Button",style:({
          color: 'rgb(' + _vm.color_r[9] + ',' + _vm.color_g[9] + ',' + _vm.color_b[9] + ')',
          'background-color':
            'rgb(' +
            _vm.background_color_r[9] +
            ',' +
            _vm.background_color_g[9] +
            ',' +
            _vm.background_color_b[9] +
            ')',
        }),on:{"click":function($event){return _vm.TapButton(9)}}},[_vm._v(" 9 ")]),_c('div',{staticClass:"Clear"}),_c('div',{staticClass:"Button",style:({
          color: 'rgb(' + _vm.color_r[10] + ',' + _vm.color_g[10] + ',' + _vm.color_b[10] + ')',
          'background-color':
            'rgb(' +
            _vm.background_color_r[10] +
            ',' +
            _vm.background_color_g[10] +
            ',' +
            _vm.background_color_b[10] +
            ')',
        }),on:{"click":function($event){return _vm.TapButton(10)}}},[_vm._v(" C ")]),_c('div',{staticClass:"Button",style:({
          color: 'rgb(' + _vm.color_r[0] + ',' + _vm.color_g[0] + ',' + _vm.color_b[0] + ')',
          'background-color':
            'rgb(' +
            _vm.background_color_r[0] +
            ',' +
            _vm.background_color_g[0] +
            ',' +
            _vm.background_color_b[0] +
            ')',
        }),on:{"click":function($event){return _vm.TapButton(0)}}},[_vm._v(" 0 ")]),_c('div',{staticClass:"Button",staticStyle:{"font-size":"30px"},style:({
          color: 'rgb(' + _vm.color_r[11] + ',' + _vm.color_g[11] + ',' + _vm.color_b[11] + ')',
          'background-color':
            'rgb(' +
            _vm.background_color_r[11] +
            ',' +
            _vm.background_color_g[11] +
            ',' +
            _vm.background_color_b[11] +
            ')',
        }),on:{"click":function($event){return _vm.TapButton(11)}}},[_c('i',{staticClass:"fas fa-backspace"})]),_c('div',{staticClass:"Clear"})])]):_vm._e(),(!_vm.commandRunning)?_c('div',{staticClass:"LoginRight"},[_c('div',{staticClass:"Upper"},[_vm._v("Guten Tag!")]),_vm._m(0)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Lower"},[_vm._v(" Bitte geben Sie Ihren "),_c('br'),_c('strong',[_vm._v("4 stelligen Code")]),_vm._v(" "),_c('br'),_vm._v(" über das Tastenfeld ein."),_c('br')])}]

export { render, staticRenderFns }